import { createStore } from 'vuex'

export default createStore({
  state: {
    navshow:false
  },
  getters: {
    
  },
  mutations: {
    changenavshow(state,show){
      console.log('commmm');
      state.navshow = show
      console.log(state.navshow );
    }
  },
  actions: {

  },
  modules: {

  }
})
