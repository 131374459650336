<template>
  <div class="home" @mousewheel="chslide">
    <Headernav ref="headernav"></Headernav>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Headernav from '@/components/Headernav/Headernav.vue'
import Content from '@/components/Content/Content.vue'
import Footer from '@/components/Footer/Footer.vue'
export default {
  name: 'Home',
  components: {
    Content,
    Footer,
    Headernav
  },
  methods: {
    chslide() {
      this.$refs.headernav.changebg()
    }
  }


}
</script>
<style lang="scss" scoped>
.bg {
  width: 100%;
  position: sticky;
  top: 0;
}
</style>>
