<template>
  <router-view />
</template>

<script>
export default {
  components: {
  },
  data() {
    return {}
  },
  created() {
    this.$router.push('/')
  }
}
</script>

